.styled-markdown-text {
  font-size: 16px;
  line-height: 1.5em;
  letter-spacing: -0.011em;
  /* overflow hidden necessary for autoresizing to work */
  overflow: hidden;
}
.styled-markdown-text h1 {
  font-size: 1.953rem;
  line-height: 2.5rem;
  letter-spacing: -0.022em;
}
.styled-markdown-text h2 {
  font-size: 1.563rem;
  line-height: 2rem;
  letter-spacing: -0.02em;
}
.styled-markdown-text h3 {
  font-size: 1.25rem;
  line-height: 1.75rem;
  letter-spacing: -0.017em;
}
.styled-markdown-text h4 {
  font-size: 1rem;
  line-height: 1.5rem;
  letter-spacing: -0.011em;
}
.styled-markdown-text h5,
h6 {
  font-size: 0.8rem;
  line-height: 1.5rem;
  letter-spacing: -0.002em;
}
.styled-markdown-text strong {
  font-weight: 600;
}
.styled-markdown-text em {
  font-style: italic;
}

.styled-markdown-text h1,
.styled-markdown-text h2,
.styled-markdown-text h3,
.styled-markdown-text h4,
.styled-markdown-text h5,
.styled-markdown-text h6 {
  font-weight: 600;
}

.styled-markdown-text p,
.styled-markdown-text ul,
.styled-markdown-text ol,
.styled-markdown-text h1,
.styled-markdown-text h2,
.styled-markdown-text h3,
.styled-markdown-text h4,
.styled-markdown-text h5,
.styled-markdown-text h6,
.styled-markdown-text li,
.styled-markdown-text li p {
  margin-bottom: 0.5rem;
}

.styled-markdown-text ul,
.styled-markdown-text ol {
  list-style: none;
  padding-left: 0;
}
.styled-markdown-text ol li,
.styled-markdown-text ul li {
  position: relative;
  padding-left: 32px;
}

.styled-markdown-text ul li::before,
.styled-markdown-text ol li::before {
  width: 24px;
  text-align: center;
  left: 0;
  position: absolute;
}

.styled-markdown-text ul ul li,
.styled-markdown-text ul ol li,
.styled-markdown-text ol ol li,
.styled-markdown-text ol ul li {
  margin-left: -8px;
}

.styled-markdown-text ul li p:empty:after,
.styled-markdown-text ol li p:empty:after {
  content: " ";
}

.styled-markdown-text ul li::before {
  content: "•";
  color: rgba(113, 81, 78, 0.5);
}
.styled-markdown-text ol {
  counter-reset: ol-counter;
}
.styled-markdown-text ol li {
  counter-increment: ol-counter;
}
.styled-markdown-text ol li::before {
  content: counter(ol-counter) ". ";
}

.styled-markdown-text blockquote {
  color: rgba(113, 81, 78, 1);
  border-left: solid 4px RGBA(113, 81, 78, 0.1);
  margin-left: 2px;
  padding-left: 10px;
  margin-bottom: 16px;
}
.styled-markdown-text hr {
  height: 40px;
  border: none;
  margin-top: 19px;
  margin-bottom: 20px;
  height: 1px;
  background: RGBA(113, 81, 78, 0.1);
}
.styled-markdown-text code {
  font-family: Menlo, source-code-pro, Monaco, Consolas, "Courier New",
    monospace;
  font-size: 0.8rem;
  line-height: 16px;
  background: RGBA(113, 81, 78, 0.05);
  padding: 4px 6px;
  border-radius: 4px;
}
.styled-markdown-text pre {
  background: RGBA(113, 81, 78, 0.05);
  padding: 10px 16px;
  border-radius: 4px;
  line-height: 16px;
  white-space: pre-wrap;
  margin-bottom: 16px;
}
.styled-markdown-text pre code {
  background: none;
}
/* These need to be scoped to direct descendant elements of the styled-markdown-text class */
/* to prevent children of children issues */
.styled-markdown-text > p:last-child,
.styled-markdown-text > ul:last-child,
.styled-markdown-text > ol:last-child,
.styled-markdown-text > pre:last-child,
.styled-markdown-text > hr:last-child,
.styled-markdown-text > blockquote:last-child {
  margin-bottom: 0;
}
/* The prosemirror div is in between the style div in the editor */
.styled-markdown-text > .ProseMirror > p:last-child,
.styled-markdown-text > .ProseMirror > ul:last-child,
.styled-markdown-text > .ProseMirror > ol:last-child,
.styled-markdown-text > .ProseMirror > pre:last-child,
.styled-markdown-text > .ProseMirror > hr:last-child,
.styled-markdown-text > .ProseMirror > blockquote:last-child {
  margin-bottom: 0;
}
