code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

/* https://www.freecodecamp.org/news/focus-rings-for-keyboard-interactions-only/ */
.focus-visible {
  outline: 2px solid #007aff;
}

/* override UA stylesheet, only when polyfill is loaded */
.js-focus-visible :focus:not(.focus-visible) {
  outline-width: 0;
}

/* http://meyerweb.com/eric/tools/css/reset/ 
   v2.0 | 20110126
   License: none (public domain)
*/

html,
body,
div,
span,
applet,
object,
iframe,
h1,
h2,
h3,
h4,
h5,
h6,
p,
blockquote,
pre,
a,
abbr,
acronym,
address,
big,
cite,
code,
del,
dfn,
em,
img,
ins,
kbd,
q,
s,
samp,
small,
strike,
strong,
sub,
sup,
tt,
var,
b,
u,
i,
center,
dl,
dt,
dd,
ol,
ul,
li,
fieldset,
form,
label,
legend,
table,
caption,
tbody,
tfoot,
thead,
tr,
th,
td,
article,
aside,
canvas,
details,
embed,
figure,
figcaption,
footer,
header,
hgroup,
menu,
nav,
output,
ruby,
section,
summary,
time,
mark,
audio,
video,
textarea,
input {
  margin: 0;
  padding: 0;
  border: 0;
  font: inherit;
  vertical-align: baseline;
  box-sizing: border-box;
  font-family: "Inter", -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto",
    "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
}
/* HTML5 display-role reset for older browsers */
article,
aside,
details,
figcaption,
figure,
footer,
header,
hgroup,
menu,
nav,
section {
  display: block;
}
body {
  line-height: 1;
}
ol,
ul {
  list-style: none;
}
blockquote,
q {
  quotes: none;
}
blockquote:before,
blockquote:after,
q:before,
q:after {
  content: "";
  content: none;
}
table {
  border-collapse: collapse;
  border-spacing: 0;
}

html {
  font-size: 14px;
}

em {
  font-weight: 500;
}

body {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-size: 14px;
  font-family: "Inter", -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto",
    "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  overflow-x: hidden;
  scrollbar-gutter: stable;
}

.visually-hidden {
  position: absolute;
  top: -99999px;
  left: -99999px;
}

/* Utility class */
.absolute-cover {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}

/* ProseMirror is used in TextBlock */
.ProseMirror {
  font: inherit;
  min-height: 100%;
  /* padding from TextBlock.tsx see padding note there */
  padding: 7px;
  white-space: break-spaces !important;
  /* stop prosemirror from turning ligatures off */
  font-variant-ligatures: normal !important;
  font-feature-settings: normal !important;
  -webkit-font-variant-ligatures: normal !important;
}

/* Used for masurement for autosizing text block */
.text-sizer .ProseMirror {
  position: absolute;
  min-height: 0;
  height: auto;
  width: auto;
}

/* contenteditable, which we use for text blocks, will not work in Safari without this setting */
/* we only want to set in when a text block is actively being edited */
.active-text-editor [contenteditable] {
  -webkit-user-select: text;
  user-select: text;
}

/* Customize website's scrollbar like Mac OS
Not supports in Firefox and IE */

/* total width */
*::-webkit-scrollbar {
  background-color: transparent;
  width: 8px;
  height: 8px;
}

/* background of the scrollbar except button or resizer */
*::-webkit-scrollbar-track {
  background-color: transparent;
}

/* scrollbar itself */
*::-webkit-scrollbar-thumb {
  background-color: #babac0;
  border-radius: 16px;
  border: 4px solid transparent;
}

/* set button(top and bottom of the scrollbar) */
*::-webkit-scrollbar-button {
  display: none;
}

.ProseMirror p.is-editor-empty:first-child::before {
  color: #adb5bd;
  content: attr(data-placeholder);
  float: left;
  height: 0;
  pointer-events: none;
}

.feedback-widget-modal {
  cursor: default;
}
.feedback-widget {
  bottom: 40px !important;
}
button.feedback-widget-form-control[type="submit"] {
  background: #f5f1ed;
}
button.feedback-widget-form-control[type="submit"]:hover {
  background: #eae1db;
}
.feedback-widget-header {
  margin-bottom: 0 !important;
}

/* No custom cursor on touch devices */
@media (hover: none) {
  .cursor {
    display: none;
  }
}
